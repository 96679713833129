var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    attrs: {
      "action-collapse": "",
      "title": "Daftar Teritori"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-tambah",
      modifiers: {
        "modal-tambah": true
      }
    }],
    attrs: {
      "variant": "gradient-primary"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('Add')) + " ")], 1), _c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "cancel-variant": "secondary",
      "ok-title": "Add",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Add"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Add ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "gradient-primary"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Hapus isian ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              _vm.showModalAdd = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalAdd,
      callback: function ($$v) {
        _vm.showModalAdd = $$v;
      },
      expression: "showModalAdd"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Provinsi",
      "label-for": "v-provinsi"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optprovinsi
    },
    on: {
      "input": function ($event) {
        return _vm.getkabkota();
      }
    },
    model: {
      value: _vm.form.provinsi,
      callback: function ($$v) {
        _vm.$set(_vm.form, "provinsi", $$v);
      },
      expression: "form.provinsi"
    }
  })], 1), _c('b-form-group', [_c('b-form-group', {
    attrs: {
      "label": "Kabupaten Kota",
      "label-for": "v-kab_kota"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkabko
    },
    model: {
      value: _vm.form.kab_kot_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kab_kot_id", $$v);
      },
      expression: "form.kab_kot_id"
    }
  })], 1)], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "teritori"
    }
  }, [_vm._v("Nama Teritori")]), _c('b-form-input', {
    attrs: {
      "label": "Nama Teritori"
    },
    model: {
      value: _vm.form.nama_teritori,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nama_teritori", $$v);
      },
      expression: "form.nama_teritori"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "keterangan"
    }
  }, [_vm._v("Keterangan")]), _c('b-form-textarea', {
    attrs: {
      "placeholder": "Keterangan",
      "rows": "3"
    },
    model: {
      value: _vm.form.keterangan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-ubah",
      "cancel-variant": "secondary",
      "ok-:title": "$t('Edit')",
      "cancel-title": "Batal",
      "centered": "",
      "title": "Form Ubah"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "info"
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" Ubah ")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "gradient-primary"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_vm._v(" Hapus isian ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function ($event) {
              _vm.showModalEdit = false;
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.showModalEdit,
      callback: function ($$v) {
        _vm.showModalEdit = $$v;
      },
      expression: "showModalEdit"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Provinsi",
      "label-for": "v-provinsi"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optprovinsi
    },
    on: {
      "input": function ($event) {
        return _vm.getkabkota();
      }
    },
    model: {
      value: _vm.form.provinsi,
      callback: function ($$v) {
        _vm.$set(_vm.form, "provinsi", $$v);
      },
      expression: "form.provinsi"
    }
  })], 1), _c('b-form-group', [_c('b-form-group', {
    attrs: {
      "label": "Kabupaten Kota",
      "label-for": "v-kab_kota"
    }
  }, [_c('v-select', {
    attrs: {
      "reduce": function (option) {
        return option.value;
      },
      "label": "text",
      "options": _vm.optkabko
    },
    model: {
      value: _vm.form.kab_kot_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kab_kot_id", $$v);
      },
      expression: "form.kab_kot_id"
    }
  })], 1)], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "teritori"
    }
  }, [_vm._v("Nama Teritori")]), _c('b-form-input', {
    attrs: {
      "label": "Nama Teritori"
    },
    model: {
      value: _vm.form.nama_teritori,
      callback: function ($$v) {
        _vm.$set(_vm.form, "nama_teritori", $$v);
      },
      expression: "form.nama_teritori"
    }
  })], 1), _c('b-form-group', [_c('label', {
    attrs: {
      "for": "keterangan"
    }
  }, [_vm._v("Keterangan")]), _c('b-form-textarea', {
    attrs: {
      "placeholder": "Keterangan",
      "rows": "3"
    },
    model: {
      value: _vm.form.keterangan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "keterangan", $$v);
      },
      expression: "form.keterangan"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function ($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Sort'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function () {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function ($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function ($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": _vm.$t('Filter'),
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search"
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function ($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Clear')))])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(no)",
      fn: function (row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function (row) {
        return [_vm.allowUpdate() ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Ubah'",
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function ($event) {
              return _vm.edit(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        }), _vm._v("Ubah ")], 1) : _vm._e(), _vm.allowDelete() && row.item.anggota.length == 0 ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "title": "'Hapus'",
            "size": "sm",
            "variant": "outline-danger"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "TrashIcon"
          }
        }), _vm._v("Hapus ")], 1) : _vm._e()];
      }
    }, {
      key: "cell(kota)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.item.kota ? data.item.kota.name : '-') + " ")];
      }
    }, {
      key: "cell(anggota)",
      fn: function (_ref) {
        var item = _ref.item;
        return _vm._l(item.anggota, function (items, index) {
          return _c('dl', [_c('dt', [_vm._v(_vm._s(items.nama_lengkap == "" ? "-" : items.nama_lengkap))])]);
        });
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('b-card', [_c('ul', _vm._l(row.item, function (value, key) {
          return _c('li', {
            key: key
          }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(value) + " ")]);
        }), 0)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": _vm.infoModal.id,
      "title": _vm.infoModal.title,
      "ok-only": ""
    },
    on: {
      "hide": _vm.resetInfoModal
    }
  }, [_c('pre', [_vm._v(_vm._s(_vm.infoModal.content))])]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }